<template>
  <AvatarImage class="AvatarImage" v-bind="props" />
</template>

<script setup lang="ts">
import { AvatarImage, type AvatarImageProps } from "radix-vue";

const props = defineProps<AvatarImageProps>();
</script>

<style lang="scss">
.AvatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
</style>

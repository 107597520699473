<template>
  <AvatarFallback class="AvatarFallback" v-bind="props">
    <slot></slot>
  </AvatarFallback>
</template>

<script setup lang="ts">
import { AvatarFallback, type AvatarFallbackProps } from "radix-vue";
import { getColorFromKey, getCssVariable } from "~/utils/colors";

const props = withDefaults(defineProps<AvatarFallbackProps>(), {
  delayMs: 100,
});

// Color key has been defined as a define model to avoid conflicting with the AvatarFallbackProps
// TODO: We should find a better implementation, because `defineModel` is not being used appropriately
const colorKey = defineModel<string>("colorKey", { default: "" });

const color = getColorFromKey(colorKey.value);

const backgroundColor = getCssVariable(color, "light");
</script>

<style lang="scss">
.AvatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: v-bind(backgroundColor);
  color: var(--gray-1);
  font-size: 14px;
  font-weight: 500;
}
</style>
